import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import Container from '../containers/manifesto'
import Article from '../components/Article/Article'

export const query = graphql`
  query ManifestoTemplateQuery($id: String!) {
    page: sanityArticle(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      heading
      content
    }
  }
`

const ManifestoPage = props => {
    const { data, errors } = props

    if (errors) {
        return (
            <GraphQLErrorList errors={errors} />
        )
    }

    const page = data && data.page

    if (!page) {
        throw new Error(
            'Missing page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
        )
    }

    return (
        <Container {...page}>
            <Article {...page} />
        </Container>
    )


}

export default ManifestoPage
